import React from "react";
import styled from "styled-components";
import ArrowRight from "../../../../../assets/img/arrow-right.inline.svg"

const OurAwards = (props) => {

    return (
        <OurAwardsStyle>
            <div className={`kuda-below--fold color-primary kuda-career-container ${props.className ? props.className : ""}`} >
                {props.heading &&
                    <div className="kuda-career-bottom-margin3">
                        <h2 className="kuda-section--heading text-center careers-h2 color-primary kuda-career-bottom-margin2">
                            {props.heading}
                        </h2>
                    </div>
                }

                <div className="kuda-below--inner">
                    <div className="kuda-summary--cards flex summary-top justify-between kuda-summary--cards-mobile">
                        {props.topFeatures?.map((item, i) =>
                            <div key={i} className="card card-item card-mobile">
                                <div className="card-icon--spacing">
                                    {item.icon}
                                </div>
                                <div className="summary-card--text">
                                    <p className="text-xlbold color-black">{item.text}</p>
                                    <a href={item.linkTo} target="_blank" rel="nofollow noopener noreferrer" className={`learn-more color-primary flex align-center cta_button`} >
                                        <span className="learn-more--icon">View Awards</span><span className="arrow-right--icon"><ArrowRight /></span>
                                    </a>

                                </div>
                            </div>)
                        }
                        {props.centerFeatures?.map((item, i) =>
                            <div key={i} className="card card-item card-mobile mobile-only">
                                <div className="card-icon--spacing">
                                    {item.icon}
                                </div>
                                <div className="summary-card--text">
                                    <p className="text-xlbold color-black">{item.text}</p>
                                    <a href={item.linkTo} target="_blank" rel="nofollow noopener noreferrer" className={`learn-more color-primary flex align-center cta_button`} >
                                        <span className="learn-more--icon">View Awards</span><span className="arrow-right--icon"><ArrowRight /></span>
                                    </a>
                                </div>
                            </div>)
                        }
                    </div>

                    {typeof props.centerFeatures !== "undefined" &&
                        <div className="kuda-summary--cards flex summary-bottom careers-no-margin-top justify-center web-only">
                            {props.centerFeatures?.map((item, i) =>
                                <div key={i} className="card card-item">
                                    <div className="card-icon--spacing">
                                        {item.icon}
                                    </div>
                                    <div className="summary-card--text">
                                        <p className="text-xlbold color-black">{item.text}</p>
                                        <a href={item.linkTo} target="_blank" rel="nofollow noopener noreferrer" className={`learn-more color-primary flex align-center cta_button`} >
                                            <span className="learn-more--icon">View Awards</span><span className="arrow-right--icon"><ArrowRight /></span>
                                        </a>
                                    </div>
                                </div>)
                            }
                        </div>
                    }
                </div>
            </div>
        </OurAwardsStyle>
    )
}

const OurAwardsStyle = styled.div`
    .kuda-career-container {
        margin-top: 160px;
    }

    .kuda-career-bottom-margin3 {
        margin-bottom: 40px;
    }

    .kuda-career-bottom-margin2 {
        margin-bottom: 12px;
    }

    .kuda-section--heading {
        font-size: 2.25rem;
        line-height: 1.38;
    }

    .summary-top .card, .summary-bottom .card {
        width: calc(32.3% - 13px);
        position: relative;
        border-radius: 4px;
    }

    .card-icon--spacing{
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;

        img{
            max-width: 100%;
            width: 100%;
        }
    }

    .summary-bottom {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px
    }
    
    .summary-bottom .card:not(:last-child) {
        margin-right: 50px;
    }

    .summary-card--text{
        margin-top: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100px;

        .learn-more{
            margin-top: 10px;
        }
    }

    .mobile-only{
        display: none;
    }

    @media only screen and (max-width: 767px) {
        .card-icon--spacing {   
            width: 100%;

            img{
                max-width: 100%;
                width: 100%;
            }
        }
        .kuda-summary--cards{
            flex-wrap: nowrap;
        }
        .summary-card--text{
            margin-top: 45%;
        }
       .kuda-summary--cards-mobile{
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
       }

       .card-mobile{
        scroll-snap-align: start;
        flex-shrink: 0;
        margin-right: 25px;
        transform-origin: center center;
        transform: scale(1);
        transition: transform 0.5s;
        position: relative;
       }

       .web-only{
         display: none !important;
       }

       .mobile-only{
        display: block;
       }

       .summary-card--text .learn-more{
            justify-content: flex-start;
        }
    
    }
`

export default OurAwards;

